import { Carousel } from '@mantine/carousel';
import { Affix, Center, Button, Blockquote, Image, BackgroundImage, Text, Group, Modal, Stack, SimpleGrid, Box, Flex, em, Title, noop } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';

export default function App() {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [optionsCounter, setOptionsCounter] = useState(0);
  const [noModal, setNoModal] = useState(false);
  const [yesModal, setYesModal] = useState(false);

  const noOptions = [
    'No',
    '¿Estás segura?',
    '¿Completamente segura?',
    'Hmm, piénsalo de nuevo',
    '¡Última oportunidad!',
    ':('
  ]

  const yesImages = [
    '/images/bed.gif',
    '/images/dancing.gif',
    '/images/hug.gif',
    '/images/kiss.gif',
  ]

  const handleNo = () => {
    setOptionsCounter(optionsCounter + 1);
    if (optionsCounter === noOptions.length - 2) {
      setNoModal(true);
    }
  }

  return (
    <div style={{ height: '100vh', display: 'flex' }}>
      <Carousel orientation="vertical" height={'100%'} style={{ flex: 1 }}>
        <Carousel.Slide style={{backgroundImage: 'linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12)'}}>
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            h={'100%'}
          >
            <Center>
              <Image
                src="/images/collage.png"
                fit='contain'
                style={{flex: 'unset'}}
                w='80%'
                radius={'lg'}
              >
              </Image>
            </Center>
            <Center>
            <Blockquote color="teal">
              <Text size='xl' radius='lg' c={'white'}>
                Gracias por tantos momentos tan bonitos a mi lado, te amo, hermosa ❤️
              </Text>
            </Blockquote>
            </Center>
          </SimpleGrid>
        </Carousel.Slide>
        <Carousel.Slide style={{backgroundImage: 'linear-gradient(to left bottom, #b500a7, #a10aa8, #8c13a9, #7419a9, #5a1ea8)'}}>
          <Center h={'100%'}>
            <Blockquote color="pink">
              <Text size='xl' radius='lg'c={'white'}>
                Espero continuemos queriéndonos y aprendiendo juntos por mucho tiempo más :)
              </Text>
            </Blockquote>
          </Center>
        </Carousel.Slide>
        <Carousel.Slide style={{backgroundImage: 'radial-gradient(circle, #e30536, #c6004f, #9f005d, #740f61, #471a5a)'}}>
          <Stack h={'100%'} align='center' justify='center'>
            <Title order={1} c={'white'} ta={'center'}>
              Will you be my valentine?
            </Title>
            <Image
              src={'/images/flowers.gif'}
              w={'350px'}
              fit={'contain'}
              radius={'lg'}
            />
            <Group justify='center'>
              <Button variant='gradient' w={'220px'} gradient={{ from: 'blue', to: 'cyan', deg: 90 }} onClick={() => setYesModal(true)}>
                Sí :)
              </Button>
              <Button variant='gradient' w={'220px'} gradient={{ from: 'red', to: 'pink', deg: 164 }} onClick={() => handleNo()}>
                {noOptions[optionsCounter]}
              </Button>
            </Group>
          </Stack>
        </Carousel.Slide>
      </Carousel>
      <Modal
        opened={noModal}
        onClose={() => {
          setNoModal(false);
          setOptionsCounter(0);
        }}
        transitionProps={{ transition: 'fade', duration: 400, timingFunction: 'linear' }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
      >
        <Image
          src='/images/sad.gif'
          alt='sad image'
          width={300}
          height={300}
        />
      </Modal>
      <Modal
        opened={yesModal}
        onClose={() => setYesModal(false)}
        transitionProps={{ transition: 'fade', duration: 400, timingFunction: 'linear' }}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
      >
        <Image
          src={yesImages[Math.floor(Math.random() * yesImages.length)]}
          alt='happy image'
          width={300}
          height={300}
        />
      </Modal>
    </div>
  )
}
